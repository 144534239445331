import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { UserService } from '@core/services/user/user.service';

export const selfResolver = (): ResolveFn<OldUserEntity> => {
  return () => {
    const userService = inject(UserService);
    return userService.getOldSelf();
  };
};
